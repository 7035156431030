@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: sticky;
  top: 0;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
  }
}

.containerMob {
  /* Size */
  width: 100%;
  height: 40px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
  }
}

.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  padding: 0 7px 0 7px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  padding: 0 7px 0 7px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}


.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;
  @media (max-width: 345px){
    padding: 15px 15px;
  }
}
.searchMenu {
  padding: 12px 24px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.freeDelivery{
  background: 'linear-gradient(to right, #F6A0AF, #FF0065)';
  text-align:'center';
  color:'black';
  padding:'0px !important';
  margin:'0px'
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.searchContainerMobile {
  position: relative;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  /* &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  } */

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}