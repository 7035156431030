@import '../../styles/customMediaQueries.css';

.root {
}
.actionButtons {
  width: 100%;
  margin: 8px 0;
}

.mobileOnlyButton {
  display: block;
  width: 100%;
  
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopOnlyButton {
  display: none;
  width: 100%;
  
  @media (--viewportMedium) {
    display: block;
  }
}

/* Update existing button styles */
.editButton {
  display: inline-block;
  width: 100%;
  padding: 12px 16px;
  text-align: center;
  
  border: 1px solid black;
  border-radius: 24px;
  
  color: var(--marketplaceColor);
  background: transparent;
  
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.editButton:hover {
  background-color: #f0f0f0;
  text-decoration: underline;
}


.contact:hover {
  background-color: #f0f0f0;
  text-decoration: underline;
}

.content {
  display: flex;
  flex-direction: row;
}

.locationField p{
  margin-top: 0px;
  margin-bottom: 10px;
  font-size:10px;
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Ensure the link container takes full width and removes unnecessary padding/margins */
.linkContainer {
  display: flex;
  flex-direction: column; /* Stack the links vertically */
  width: 100%; /* Ensure full width of the parent container */
  padding: 0; /* Remove padding */
  margin: 20px 0 16px 0; /* Add space above and below the links */
  align-items: stretch; /* Ensure links stretch to full width */
}

/* Ensure each link/button takes full width */
.link {
  display: block;
  padding: 12px 16px;
  border: 1px solid black;
  border-radius: 24px;
  width: 100%; /* Take full width */
  box-sizing: border-box; /* Include padding and border in the width */
  text-align: center; /* Center the text */
  max-width: none; /* Disable any max-width that may have been applied */
  font-size: 14px;
}

.link:hover {
  background-color: #f0f0f0;
}

/* Adjust the content and info containers */
.content {
  display: flex;
  flex-direction: row;
  width: 100%; /* Ensure the content takes full width */
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure the info takes full width */
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.avatarDisabled {
  flex-shrink: 0;
  margin-right: 36px;
  pointer-events: none;
}



.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.avatarDisabled {
  flex-shrink: 0;
  margin-right: 36px;
  pointer-events: none;
}

/* Bio and links align properly */
.mobileBio,
.desktopBio {
  white-space: pre-line;
  margin: 18px 0 0 0;
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}


.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.avatarDisabled{
  flex-shrink: 0;
  margin-right: 36px;
  pointer-events: none;
}

.info {
  flex: 1;
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
  padding: 7px 16px;
  border: 1px solid black;
  border-radius: 24px;
  width: 100%;
}

.links :hover{
  background-color:  #f0f0f0;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 0px;
  margin-bottom: 0px;

  @media (min-width: 417px) {
    /* margin: 10px 0 0 0; */
  }
  @media (min-width: 768px) {
    /* margin: 5px 0 0 0; */
  }
}

.withBioMissingAbove {
  margin-top: 4px;
  margin-bottom: 4px;
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  padding: 0px;
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  width: 100%;
  padding: 7px 16px;

  /* Borders */
  border: 1px solid black;
  border-radius: 24px;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}

.info p :hover{
  background-color:  #f0f0f0;
}
